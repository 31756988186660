/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

/* global picturefill */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.

  var Contrast = {
	// All pages
	'common': {
	  init: function() {

	  	var currentState;

	  	picturefill();

	  	if ($.mediaquery) {
		  	$.mediaquery({
				minWidth     : [ 320, 500, 740, 980, 1220 ],
				maxWidth     : [ 1220, 980, 740, 500, 320 ],
				minHeight    : [ 400, 800 ]
			});

			$(window).on("mqchange.mediaquery", function(e, state) {
				currentState = state;
				
				console.log(state);

				if ( currentState.maxWidth === 500 ) {
					$('.js-hover').removeClass('hover');
				}
			});

			currentState = $.mediaquery("state");
		}

		// Mobile Navigation
		$('.js-mobile-navigation').navigation({
			maxWidth: Infinity,
			type: 'overlay',
			theme: 'no-theme',
			label: false
		});

		$('.js-carousel').carousel({
			theme: 'no-theme'
		});

		$('.js-lightbox').lightbox({
			theme: 'fs-light'
		});

		$('.js-background').background();

		$('.js-dropdown').dropdown({
			theme: 'no-theme'
		});

		$('.gfield_select').dropdown({
			theme: 'no-theme',
			customClass: 'gf-dropdown'
		});

		$(document).bind('gform_post_render', function() {
			$('.gfield_select').dropdown({
				theme: 'no-theme',
				customClass: 'gf-dropdown'
			});
		});

		$('.js-hover').hammer().bind('tap', function(e) {
			'use strict';

			var $this = $(this);

			if (currentState.maxWidth > 500) {
				if ($this.hasClass('hover')) {
					return true;
				} else {
					$this.addClass('hover');
					$('.js-hover').not(this).removeClass('hover');
					e.preventDefault();
					return false;
				}
			}
			
		});

		// $('.js-equalize').equalize();
		
		// Video Embed
		$('.js-embed-video').on('click', function (e) {
			e.preventDefault();

			var	$this = $(this),
				$video = $this.attr('href'),
				$iframe;

			$iframe = $('<iframe />', { class: '', src: $video + '?title=0&byline=0&portrait=0&autoplay=1&badge=0' })
				.attr('allowfullscreen', '')
				.attr('webkitallowfullscreen', '')
				.attr('mozallowfullscreen', '')
				.wrap('<div class="video-frame"></div>');

			$this.parent()
				 .before($iframe.parent())
				 .parents('.js-embed-parent')
				 .addClass('expanded');
		});

	  },
	  finalize: function() {
		// JavaScript to be fired on all pages, after page specific JS is fired
	  }
	},
	// Home page
	'home': {
	  init: function() {
		// JavaScript to be fired on the home page
	  },
	  finalize: function() {
		// JavaScript to be fired on the home page, after the init JS
	  }
	},
	// About us page, note the change from about-us to about_us.
	'about_us': {
	  init: function() {
		// JavaScript to be fired on the about us page
	  }
	},
	'post_type_archive_projects': {
		init: function() {
			$('.js-project-dropdown').on('change', function(e) {
				var $this = $(this),
					$form = $this.parents('.projects-header-form');

				$form.submit();
			});
		}
	}
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
	fire: function(func, funcname, args) {
	  var fire;
	  var namespace = Contrast;
	  funcname = (funcname === undefined) ? 'init' : funcname;
	  fire = func !== '';
	  fire = fire && namespace[func];
	  fire = fire && typeof namespace[func][funcname] === 'function';

	  if (fire) {
		namespace[func][funcname](args);
	  }
	},
	loadEvents: function() {
	  // Fire common init JS
	  UTIL.fire('common');

	  // Fire page-specific init JS, and then finalize JS
	  $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
		UTIL.fire(classnm);
		UTIL.fire(classnm, 'finalize');
	  });

	  // Fire common finalize JS
	  UTIL.fire('common', 'finalize');
	}
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
